
  import { computed, defineComponent, ref, getCurrentInstance } from "vue"
  import { CollectionResourceDoc } from '@/models/jsonapi'
  import { Agency } from "@/models/agencies";
  import { BvTableCtxObject } from "bootstrap-vue"
  import TitleActionsNavbar from "@/components/shared/TitleActionsNavbar.vue"
  import AgencyFormEdit from "@/components/shared/AgencyFormEdit.vue"

  export default defineComponent({
    components: {
      TitleActionsNavbar,
      AgencyFormEdit
    },
    setup(_) {
      const root = getCurrentInstance().proxy
      const createModal = ref()
      const totalItems = computed<number>(() => root.$store.state.agencies.pagination.totalItems)
      const getItems = async(ctx: BvTableCtxObject) => {
        const queryParams = {
          'page[number]': ctx.currentPage,
          'page[size]': ctx.perPage,
        }
        await root.$store.dispatch('agencies/load', queryParams)
        return root.$store.state.agencies.data
      }

      const columns = ref([
        // {
        //   component: () => import("@/components/shared/table/cells/LinkCell.vue"),
        //   type: "Def",
        //   key: "householdFamilyNames",
        //   title: "Vertrag mit",
        //   sortable: true,
        //   options: {
        //     contentFunction: (data:any) => {
        //       const crs = data.item.attributes.household.attributes.care_receivers
        //       const familyName = crs.map(cr => cr.attributes.address.attributes.lastName).join(', ').trim()
        //       if (crs.length == 0) {return 'ERROR! Keine Patienten'}
        //       return familyName !== '' ? familyName : 'Keiner vorhanden'
        //     },
        //     hrefFunction: (data: any) => `/contracts/${data.item.id}`
        //   }
        // },
        {
          component: () => import("@/components/shared/table/cells/LinkCell.vue"),
          key: "attributes.name",
          title: "Agentur",
          sortable: true,
            options: { hrefFunction: (data: any) => `/agencies/${data.item.id}` }
        },
        {
          component: () => import("@/components/shared/table/cells/ValueCell.vue"),
          key: "attributes.holidays",
          title: "Urlaubstage",
          sortable: true,
          options: {
            contentFunction: (data:any) => data.item.attributes.holidays.length
          }
        },
        {
          component: () => import("@/components/shared/table/cells/ValueCell.vue"),
          key: "attributes.dubHh",
          title: "2 Pers. HH",
          sortable: true,
          options: {
            contentFunction: (data:any) => {
              const ag = data.item.attributes
              return [ag.dubHh, ag.dubHhUpper].join('-').replace(/-$/,'')
            }
          }
        },
        {
          component: () => import("@/components/shared/table/cells/ValueCell.vue"),
          key: "attributes.dubCr",
          title: "2 Patienten",
          sortable: true,
          options: {
            contentFunction: (data:any) => {
              const ag = data.item.attributes
              return [ag.dubCr, ag.dubCrUpper].join('-').replace(/-$/,'')
            }
          }
        },
        // {
        //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
        //   key: "attributes.clientSigned",
        //   title: "Sig. Kd.",
        //   sortable: true,
        // },
        // {
        //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
        //   key: "attributes.agencySigned",
        //   title: "Sig. Ag.",
        //   sortable: true,
        // },
        // {
        //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
        //   key: "attributes.active",
        //   title: "Aktiv",
        //   sortable: true,
        // },
        // {
        //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
        //   key: "attributes.archived",
        //   title: "Archiviert",
        //   sortable: true,
        // },
      ])

      const requestNewAgencyId = () => {
        root.$store.dispatch('agencies/create', { name: "Neue Agentur" })
          .then((r) => {
            root.$router.push(`agencies/${r.data.id}`)
          })
        
      }

      const titleActions = ref([
        {
          title: 'Neue Agentur',
          icon: 'plus',
          tooltip: 'Neue Agentur anlegen',
          onClick: () => createModal.value.show()
        }
      ])

      return {
        getItems,
        totalItems,
        columns,
        titleActions,
        createModal
      }
    }
  })
